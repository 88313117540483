/**
 * @file
 * Visual styles for a resizable textarea.
 */

.form-textarea-wrapper textarea {
  display: block;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}
