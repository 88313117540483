/**
 * @file
 * Styles for Bootstrap Barrio page.
 */

/* Hack for .placeholder issue https://www.drupal.org/project/drupal/issues/3233533 */
em.placeholder {
  vertical-align: unset!important;
  cursor: unset!important;
  background-color: unset!important;
  opacity: unset!important;
}