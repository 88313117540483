/**
 * @file
 * Visual styles for Bootstrap Barrio's field components.
 */

.field--type-entity-reference {
    margin: 0 0 ($spacer * 1.2);
}

.field--type-entity-reference .field__label {
    font-weight: normal;
    margin: 0;
    padding-right: ($spacer * .3);
    /* LTR */
}

[dir="rtl"] .field--type-entity-reference .field__label {
    padding-left: ($spacer * .3);
    padding-right: 0;
}

.field--type-entity-reference ul.links {
    padding: 0;
    margin: 0;
    list-style: none;
}

.field--type-entity-reference ul.links li {
    float: left;
    /* LTR */
    padding: 0 $spacer 0 0;
    /* LTR */
    white-space: nowrap;
}

[dir="rtl"] .field--type-entity-reference ul.links li {
    padding: 0 0 0 $spacer;
    float: right;
}

@media all and (min-width: 560px) {
    .node .field--type-image {
        float: left;
        /* LTR */
        margin: 0 $spacer 0 0;
        /* LTR */
    }
    [dir="rtl"] .node .field--type-image {
        float: right;
        margin: 0 0 0 $spacer;
    }
    .node .field--type-image+.field--type-image {
        clear: both;
    }
}

.field--type-image img,
.field--name-field-user-picture img {
    margin: 0 0 $spacer;
}

.field--type-image a {
    border-bottom: none;
}

.field--name-field-tags {
    margin: 0 0 ($spacer * 1.2);
}

.field--name-field-tags .field__label {
    font-weight: normal;
    margin: 0;
    padding-right: 5px;
    /* LTR */
}

[dir="rtl"] .field--name-field-tags .field__label {
    padding-left: 5px;
    padding-right: 0;
}

.field--name-field-tags .field__label,
.field--name-field-tags ul.links {
    font-size: $font-size-sm;
}

.node--view-mode-teaser .field--name-field-tags .field__label,
.node--view-mode-teaser .field--name-field-tags ul.links {
    font-size: $font-size-sm;
}

.field--name-field-tags ul.links {
    padding: 0;
    margin: 0;
}

.field--name-field-tags ul.links li {
    float: left;
    /* LTR */
    padding: 0 $spacer 0 0;
    /* LTR */
    white-space: nowrap;
}

[dir="rtl"] .field--name-field-tags ul.links li {
    padding: 0 0 0 $spacer;
    float: right;
}

.field--label-inline .field__label,
.field--label-inline .field__item {
    display: inline-block
}