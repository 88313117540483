/**
 * @file
 * Visual styles for the Shortcut action link in Bootstrap Barrio.
 */

.shortcut-wrapper {
  margin: 2.2em 0 1.1em 0; /* Same as usual .page-title margin. */
}
.shortcut-wrapper .page-title {
  float: left; /* LTR */
  margin: 0;
}
[dir="rtl"] .shortcut-wrapper .page-title {
  float: right;
}
.shortcut-action {
  border-bottom: none;
  margin-left: 0.5em; /* LTR */
  padding-top: 0.35em;
}
[dir="rtl"] .shortcut-action {
  margin-left: 0;
  margin-right: 0.5em;
}
.shortcut-action:hover,
.shortcut-action:active,
.shortcut-action:focus {
  border-bottom: none;
}
