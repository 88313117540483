/**
 * @file
 * Visual styles for icons.
 */

.icon-help {
	background: url(/../../images/help.png) 0 50% no-repeat;
	/* LTR */
	padding: 1px 0 1px ($spacer * 1.5);
	/* LTR */
}

[dir="rtl"] .icon-help {
	background-position: 100% 50%;
	padding: 1px ($spacer * 1.5) 1px 0;
}

.feed-icon {
	@include svg-icon-replace(rss-fill, $orange, $orange, $font-size-base);
}