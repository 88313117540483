/**
 * @file
 * Visual styles for Bartik's dropbutton component.
 */

.js .dropbutton-wrapper .dropbutton-widget {
  /* This is required to win over specifity of .js td .dropbutton-widget */
  position: relative;
}
.js .dropbutton-widget {
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4 #d2d2d2;
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e8e8e8);
  background-image: linear-gradient(to bottom, #f3f3f3, #e8e8e8);
  color: #3a3a3a;
  cursor: pointer;
  text-align: center;
  margin: 0.125em 0;
  border-radius: 1em;
  overflow: hidden;
}
.js .dropbutton-widget:hover {
  border-color: #e4e4e4 #d2d2d2 #b4b4b4 #d2d2d2;
}
.js .dropbutton-widget .button {
  border: none;
  margin: 0;
  padding: 0.32em 1em;
  background: transparent none;
}
.js .dropbutton-multiple .dropbutton-widget .dropbutton-action a {
  margin-right: 0; /* LTR */
}
[dir="rtl"].js .dropbutton-multiple .dropbutton-widget .dropbutton-action a {
  margin-left: 0;
}
.js .dropbutton .secondary-action {
  border-top-color: #ccc;
}
.js .dropbutton-toggle button {
  background-color: #e8e8e8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #d2d2d2);
  background-image: linear-gradient(to bottom, #e8e8e8, #d2d2d2);
}
.js .dropbutton-toggle .dropbutton-arrow:hover {
  background: #ccc;
}
.js .dropbutton a {
  color: #3a3a3a;
  border-bottom: 0 none;
}
.js .dropbutton .dropbutton-action:hover,
.js .dropbutton a:hover {
  background: #dedede;
  border-bottom: 0 none;
}
