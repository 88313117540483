/**
 * @file
 * Bootstrap Barrio specific styling for the Book module.
 */

.book-navigation .menu {
    border-top: 1px solid $border-color;
}

.book-navigation .book-pager {
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    margin: 0;
}