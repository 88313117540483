/**
 * @file
 * List group.
 * A list of links, grouped together.
 */

.list-group__link {
    border-top: $border-width solid $border-color;
    padding: $list-group-item-padding-y 0 0;
}

.list-group__description {
    margin: 0 0 $list-group-item-padding-y;
}