/**
 * @file
 * Table select behavior.
 *
 * @see tableselect.js
 */

tr.selected td {
  background: #ffc;
}
td.checkbox,
th.checkbox {
  text-align: center;
}
[dir="rtl"] td.checkbox,
[dir="rtl"] th.checkbox {
  /* This is required to win over specificity of [dir="rtl"] td */
  text-align: center;
}
