/**
 * @file
 * Collapsible details.
 *
 * @see collapse.js
 * @see http://nicolasgallagher.com/css-background-image-hacks/
 */

details {
    border: 1px solid $border-color;
    margin-top: $spacer;
    margin-bottom: $spacer;
}

details>.details-wrapper {
    padding: 0.5em 1.5em;
}


/* @todo Regression: The summary of uncollapsible details are no longer
     vertically aligned with the .details-wrapper in browsers without native
     details support. */

summary {
    cursor: pointer;
    padding: 0.2em 0.5em;
}