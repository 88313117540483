/**
 * @file
 * Theme styling for user module.
 */


/* Visual styling for the Password strength indicator */

.password-strength__meter {
  margin-top: 0.5em;
  background-color: $gray-200;
}

.password-strength__indicator {
  background-color: map-get($theme-colors,"success");
  -webkit-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
}

.password-strength__indicator.is-weak {
    background-color: map-get($theme-colors,"danger");

}

.password-strength__indicator.is-fair {
  background-color: map-get($theme-colors,"warning");

}

.password-strength__indicator.is-good {
  background-color: map-get($theme-colors,"info");

}

.password-strength__indicator.is-strong {
  background-color: map-get($theme-colors,"success");

}

.password-confirm,
.password-field,
.password-strength,
.password-confirm-match {
  width: 55%;
}

.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  max-width: 34.7em;
  border: $border-width solid $border-color;
}

.password-suggestions ul {
  margin-bottom: 0;
}

.confirm-parent,
.password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  max-width: 33em;
  overflow: hidden;
}

[dir="rtl"] .confirm-parent,
[dir="rtl"] .password-parent {
  clear: right;
}


/* Styling for the status indicator of the passwords match test.  */

.password-confirm .ok {
  color: map-get($theme-colors,"success");
  font-weight: bold;
}

.password-confirm .error {
  color: map-get($theme-colors,"danger");
  font-weight: bold;
}


/* Login page stype */

.page-user-login main .region-content,
.page-user-password main .region-content {
  max-width: 400px;
  background: white;
  padding: $spacer $spacer;
  margin: 0 auto;
  border-radius: $border-radius-lg;
}

body.path-user {
  .field--label-inline {
    .field__label {
      width: 100px;
      color: $primary-shade
    }
    .field__item {
      padding: calc($spacer / 4) $spacer;
      border-radius: $spacer;
      background: $gray-200;
      min-width: 250px;
      margin: calc($spacer / 4) 0;
    }
  }

  .field--label-above {
    .field__label {
      color: $primary-shade
    }
    .field__item {
      padding: calc($spacer / 4) $spacer;
      border-radius: $spacer;
      background: $gray-200;
      width: 100%;
      margin: calc($spacer / 4) 0;
    }
  }
}
