/* IMPORTS */

//@import url(font-awesome.min.css);
// variables
@import "variables";
// typography
@import "typography";
//bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";
//material design bootstrap
//@import "../node_modules/mdbootstrap/scss/mdb-free.scss";
// mixins
@import "mixins";
// barrio
@import "../../../contrib/bootstrap_barrio/scss/barrio";
