/**
 * @file
 * Theme styling for tabs.
 */

nav.tabs {
  margin-bottom: $spacer;
}

nav.tabs {
	ul {
		background: $gray-200;
		display: flex;
		.nav-item {
			flex-grow: 1;
			a {
				color: $gray-800;
				text-align: center;
			}
			&.active {
				a {
					background: $accent-light;
				}
			}
		}
	}
}