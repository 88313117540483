/**
 * @file
 * Visual styles for comments in Barrio.
 */

 .alert-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 75vh;
  overflow: scroll;
  z-index: 9999;
}

.toast-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}