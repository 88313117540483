/* ------------------ Header ------------------ */

#navbar-top {
    padding: 0 1rem;
}

#navbar-top.navbar-dark .nav-link:hover,
#navbar-top.navbar-dark .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

#navbar-top.navbar-dark .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

#navbar-top.navbar-light .nav-link:hover,
#navbar-top.navbar-light .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

#navbar-top.navbar-light .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-brand .site-logo {
    margin-right: 1rem;
}

@media (max-width: 33.9em) {
    .navbar-toggleable-xs .navbar-nav .nav-item+.nav-item,
    .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
        margin-left: 0;
        float: none;
    }
}

@media (max-width: 47.9em) {
    .navbar-toggleable-sm .navbar-nav .nav-item+.nav-item,
    .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
        margin-left: 0;
        float: none;
    }
}