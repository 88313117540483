/* TYPOGRAPHY */

/* Google Fonts */

//@import url("https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic,900,900italic");
//@import url("https://fonts.googleapis.com/css?family=Raleway:400,300,500,600,700,800,900");
// font families
$font-lato: 'Lato',
Arial,
Verdana,
sans-serif;
$font-raleway: 'Raleway',
Arial,
Verdana,
sans-serif;
// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-serif: Georgia,
"Times New Roman",
Times,
serif !default;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;
$font-size-xs: $font-size-base * .75;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;