/* VARIABLES */

// colors
$primary-shade: rgb(36, 140, 199);
$primary-light: lighten($primary-shade, 37%);
$primary-dark: darken($primary-shade, 12%);
$accent-shade: #28a745;
$accent-light: lighten($accent-shade, 37%);
$accent-dark: darken($accent-shade, 12%);
// Reassign color vars to semantic color scheme
$red: #dc3545;
$yellow: #ff9800;
$green: #28a745;
$cyan: #17a2b8;
$white: #fff;
$gray-100: #f8f9fa;
$gray-800: #343a40;
$theme-colors: (
  primary: $primary-shade,
  secondary: $primary-shade,
  success: $green,
  info: #607d8b,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);
$brand-primary: $primary-shade;
$brand-success: $accent-shade;
$brand-info: $cyan;
$brand-warning: $yellow;
$brand-danger: $red;
$brand-inverse: $primary-shade;
// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-800;
$inverse-bg: $primary-shade;
$inverse-color: $white;
// Links
//
// Style anchor elements.
$link-color: $accent-shade;
$link-decoration: none;
$link-hover-color: $accent-dark;
$link-hover-decoration: none;
// Comments
$comment-spacer-x: 1.25em;
$comment-spacer-y: 1.25em;
// Responsive font sizes
$enable-responsive-font-sizes: true;
$enable-shadows: false;
$enable-gradients: false;
// Breadcrumbs (Works only on Bootstrap 5+)
// $breadcrumb-divider: quote(">");


$pagination-active-bg: $accent-shade;
$pagination-active-border-color: $accent-shade;
$pagination-hover-bg: $accent-shade;
$pagination-hover-border-color: $accent-shade;
$pagination-hover-color: $white;

$navbar-dark-color: $white;
