/**
 * @file
 * Styling for the Book module.
 */

.book-navigation .menu {
  padding-bottom: 0;
  padding-top: 1em;
}
.book-navigation .book-pager {
  margin: 0;
  overflow: auto;
  padding: 0.5em 0;
}
.book-pager__item {
  display: inline-block;
  list-style-type: none;
  vertical-align: top;
}
.book-pager__item--previous {
  text-align: left; /* LTR */
  width: 45%;
}
[dir="rtl"] .book-pager__item--previous {
  float: right;
  text-align: right;
}
.book-pager__item--center {
  text-align: center;
  width: 8%;
}
.book-pager__item--next {
  float: right; /* LTR */
  text-align: right; /* LTR */
  width: 45%;
}
[dir="rtl"] .book-pager__item--next {
  float: left;
  text-align: left;
}
